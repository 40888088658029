export const environment = {
  production: false,
  baseApi: 'https://plataforma-backend-dev.keepee.com.br/api',
  baseApiCustom: 'https://api-custom.keepee.com.br',
  Ambiente_Base: '/Ambiente',
  AtividadeExtra_Base: '/AtividadeExtra',
  Caixinha_Base: '/Caixinha',
  Colaborador_Base: '/Colaborador',
  Equipamento_Base: '/Equipamento',
  EquipamentoUnidade_Base: '/EquipamentoUnidade',
  Especialidade_Base: '/Especialidade',
  Evento_Base: '/Evento',
  FeriadosRecessos_Base: '/FeriadosRecessos',
  Ferias_Base: '/Ferias',
  Financeiro_Base: '/Financeiro',
  GeoLocationOrders_Base: '/GeoLocationOrders',
  Graficos_Base: '/Graficos',
  HoraExtra_Base: '/HoraExtra',
  Imagens_Base: '/Imagens',
  ImagensChecklist_Base: '/ImagensChecklist',
  Jornada_Base: '/Jornada',
  Justificativa_Base: '/Justificativa',
  Notificacoes_Base: '/Notificacoes',
  Orcamento_Base: '/Orcamento',
  OrcamentoMaterial_Base: '/OrcamentoMaterial',
  OrdemServicoChecklist_Base: '/OrdemServicoChecklist',
  OrdemServicoComunicacao_Base: '/OrdemServicoComunicacao',
  OrdemServicoMembroEquipe_Base: '/OrdemServicoMembroEquipe',
  OrdemServicoPesquisaSatisfacao_Base: '/OrdemServicoPesquisaSatisfacao',
  OrdemServicoVisita_Base: '/OrdemServicoVisita',
  OrdensServico_Base: '/OrdensServico',
  PersonalizarDashboard_Base: '/PersonalizarDashboard',
  Pessoa_Base: '/Pessoa',
  Security_Base: '/Security',
  Situacao_Base: '/Situacao',
  Solicitante_Base: '/Solicitante',
  Unidade_Base: '/Unidade',
  Values_Base: '/Values',
  googleMapsApiKey: 'IzaSyCRea9pybNArgBUZTHENVJNR6jNA1XqmMg'
};
